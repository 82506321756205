import { Button } from '@mui/material'
import Link from 'next/link'
import { ROUTES } from '../../utils/routes'
import { useTranslation } from '../../utils/i18n'
import ExitToApp from '@mui/icons-material/ExitToApp'

export function SingInButton({ onClick, sx }) {
  const { t } = useTranslation('common.pop_up')
  return (
    <Link href={ROUTES.login} prefetch={false} legacyBehavior passHref>
      <Button
        autoFocus
        variant="contained"
        color="primary"
        sx={{
          color: 'var(--sm-color-light)',
          ml: 10,
          ':visited': { color: 'var(--sm-color-light)' },
          ...sx
        }}
        onClick={onClick}
        component="a"
      >
        <ExitToApp sx={{ mr: 0.5 }} />
        {t('sign_in')}
      </Button>
    </Link>
  )
}
