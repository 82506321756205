'use client'
import { createContext, useState, useRef, useEffect, useContext } from 'react'
import rosetta from 'rosetta'
import { Language } from '../i18n/settings'
import { Translations } from '../i18n/useTranslation'
// import rosetta from 'rosetta/debug';

const i18n = rosetta()

const defaultLanguage = 'en'

const I18nContext = createContext()

// default language
i18n.locale(defaultLanguage)

export function I18nProvider({ children, locale = defaultLanguage, lngDict }) {
  const activeLocaleRef = useRef(locale)
  const [, setTick] = useState(0)
  const firstRender = useRef(true)

  const i18nWrapper = {
    activeLocale: activeLocaleRef.current,
    t: i18n.t,
    locale(l, dict) {
      i18n.locale(l)
      activeLocaleRef.current = l
      if (dict) {
        i18n.set(l, dict)
      }
      // force rerender to update view
      setTick(tick => tick + 1)
    }
  }

  // for initial SSR render
  if (locale && firstRender.current === true) {
    firstRender.current = false
    i18nWrapper.locale(locale, lngDict)
  }

  // when locale is updated
  useEffect(() => {
    if (locale) {
      i18nWrapper.locale(locale, lngDict)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngDict, locale])

  return (
    <I18nContext.Provider value={i18nWrapper}>{children}</I18nContext.Provider>
  )
}

type PropertyPath<T> = T extends string
  ? T
  : T extends object
    ? { [K in keyof T]: `${K}.${PropertyPath<T[K]>}` }[keyof T]
    : never

type TypedPropertyPath = PropertyPath<Awaited<ReturnType<Translations['en']>>>

export function useTranslation<T extends TypedPropertyPath>(ns: T) {
  const { t } = useContext(I18nContext)

  return {
    t: (
      key: Awaited<ReturnType<Translations['en'][typeof ns]>>,
      data?: Record<string, unknown>,
      locale?: Language
    ) => t(`${ns}.${key}`, data, locale)
  }
}
