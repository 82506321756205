// import { createContext, useState, useMemo, useEffect } from 'react'
// import { useRouter } from 'next/navigation'
// import { onAuthStateChanged, signOut, getAuth } from '@firebase/auth'
import { useUser } from '@auth0/nextjs-auth0/client'

// import { ROUTES } from '../../utils/routes'
// import { setToken } from '../../utils/api'
// import { firebaseApp } from '../../utils/firebase'

// const AuthContext = createContext({
//   user: null,
//   state: 'idl'
// })

// export function AuthProvider({ children }) {
//   const [state, setSesstionState] = useState('idl')
//   const [user, setUser] = useState(null)
//   const { push, pathname } = useRouter()

//   useEffect(() => {
//     setSesstionState('loading')
//     setTimeout(getSession, 1_500)
//   }, [])

//   function getSession() {
//     onAuthStateChanged(getAuth(firebaseApp), async user => {
//       setSesstionState('loaded')
//       if (user?.displayName) {
//         try {
//           setToken(`Barer ${user.accessToken}`)
//           setUser({ name: user.displayName, id: user.uid })

//           if (pathname === ROUTES.login) push(ROUTES.home)
//         } catch (error) {
//           console.error(error)
//         }
//       }
//     })
//   }

//   async function doSignOut() {
//     logEvent('sign_out', user)
//     await signOut(getAuth(firebaseApp))
//     setToken()
//     setUser(null)
//     setSesstionState('loaded')

//     for (const key of ['user-favorites-fixture', 'user-favorites-league']) {
//       localStorage.removeItem(key)
//     }
//   }

//   const value = useMemo(
//     () => ({ doSignOut, user, setUser, state }),
//     // eslint-disable-next-line
//     [user, state]
//   )

//   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
// }

export function useAuth() {
  return useUser()
  // return useContext(AuthContext)
}
