'use client'
import { createContext, useContext, useState, useMemo, useRef } from 'react'
import Link from 'next/link'
import { Snackbar } from '@mui/material'
import useQuery from 'swr'

import { useAuth } from '../../auth/hooks'
import { PopUp } from '../../shared/PopUp'
import { SingInButton } from '../../shared/SignInBtn'
import { ROUTES } from '../../../utils/routes'
import { useTranslation } from '../../../utils/i18n'
import { api } from '../../../utils/api'

const FavoriteContext = createContext()

const initial = () =>
  new Map([
    ['fixture', new Set()],
    ['league', new Set()],
    ['team', new Set()]
  ])

const MAP_PATH = {
  fixture: ROUTES.match,
  league: ROUTES.league,
  team: ROUTES.team
}

const message = {
  fixture: 'saving_favourite_matches',
  league: 'saving_favourite_leagues',
  team: 'saving_favourite_teams'
}

let title

async function fetchData({ userId }) {
  const data = await api('user/favorites', {
    searchParams: { userId }
  }).json()

  return data.favorites
}

async function addData(body) {
  await api(`user/favorites`, {
    method: 'put',
    body: JSON.stringify(body)
  }).json()
}

async function deleteData({ id, userId }) {
  await api(`user/favorites/${id}`, {
    method: 'delete',
    searchParams: {
      userId
    }
  }).json()
}

function storeToLocalStorage(favorites) {
  for (const [key, set] of favorites) {
    localStorage.setItem(`user-favorites-${key}`, JSON.stringify([...set]))
  }
}

function getFromLocalStorage() {
  const i = initial()
  return i
  // if (typeof window === 'undefined') return i

  // for (const key of [
  //   'user-favorites-league',
  //   'user-favorites-fixture',
  //   'user-favorites-team'
  // ]) {
  //   const value = localStorage.getItem(key)
  //   if (value) {
  //     const [, , key2] = key.split('-')

  //     i.set(key2, new Set([...JSON.parse(value)]))
  //   }
  // }

  // return i
}

export function FavoritesProvider({ children }) {
  const { user, isLoading } = useAuth()
  const [isOpen, setOpen] = useState(false)
  const [isOpenSnackbar, setOpenSnackbar] = useState(false)
  const [favorites, setFavorites] = useState(getFromLocalStorage())
  const lastAdded = useRef({})
  const { t } = useTranslation('common.pop_up')

  useQuery(user ? 'favorites' : null, () => fetchData({ userId: user.sub }), {
    onSuccess(data) {
      const i = initial()

      for (const v of data) {
        i.get(v.entity).add(v.id)
      }

      setFavorites(i)
      storeToLocalStorage(i)

      return data
    }
  })

  const isFavorite = (id, path) => favorites.get(path).has(id)

  const setFavorite = ({ id, slug }, path) => {
    if (!user) {
      title = message[path]
      return setOpen(true)
    }

    const action = isFavorite(id, path) ? 'delete' : 'add'
    lastAdded.current = { slug, action, path }
    favorites.get(path)[action](id)

    setFavorites(favorites)
    storeToLocalStorage(favorites)

    let promise
    if (action === 'add') {
      promise = addData({ id, entity: path, userId: user.sub })
    } else {
      promise = deleteData({ id, userId: user.sub })
    }

    promise.then(() => setOpenSnackbar(true))
  }

  const getSize = () =>
    favorites.get('fixture').size +
    favorites.get('league').size +
    favorites.get('team').size

  const value = useMemo(
    () => ({
      isFavorite,
      setFavorite,
      favorites
    }),
    [!!user, isLoading, getSize()]
  )

  return (
    <FavoriteContext.Provider value={value}>
      <PopUp
        label={title}
        button={<SingInButton sx={{ ml: 2 }} />}
        open={isOpen}
        setOpen={setOpen}
      />
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={2_000}
        onClose={() => setOpenSnackbar(false)}
        message={lastAdded.current.action === 'add' ? t('added') : t('removed')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '@media (max-width: 1366px)': {
            bottom: '70px'
          }
        }}
        action={
          <Link
            href={`${MAP_PATH[lastAdded.current.path]}${
              lastAdded.current.slug
            }`}
            prefetch={false}
            passHref
            legacyBehavior
          >
            <a style={{ color: 'white' }}>{'view'}</a>
          </Link>
        }
        severity="success"
      />
      {children}
    </FavoriteContext.Provider>
  )
}

export function useFavorite({ id, slug } = {}, path) {
  const { isFavorite, setFavorite, favorites } = useContext(FavoriteContext)

  return {
    get isFavorite() {
      return isFavorite(id, path)
    },
    setFavorite: () => setFavorite({ id, slug }, path),
    favorites
  }
}
