// const headers = new Headers()

function removeEmpty(params) {
  const newParams = {}

  for (const param of Object.keys(params)) {
    const value = params[param]

    if (value !== undefined) {
      newParams[param] = value
    }
  }

  return newParams
}

class NotFoundError extends Error {
  code = 404
}

export let api = (route, params = {}) => {
  let url = `${process.env.NEXT_PUBLIC_BASE_API_URL}/${route}`

  if (params.searchParams) {
    url += `?${new URLSearchParams(
      removeEmpty(params.searchParams)
    ).toString()}`
    delete params.searchParams
  }

  const promise = fetch(url, {
    ...params
    // headers
  })

  return {
    get ok() {
      return promise.then(response => response.ok)
    },
    json() {
      return promise.then(response => {
        if (response.ok) {
          const length = response.headers.get('content-length') ?? 1

          return +length ? response.json() : undefined
        }
        if (response.status === 404) throw new NotFoundError()

        throw new Error(`API error: ${response.status}`)
      })
    }
  }
}

// export const setToken = token => headers.set('Authorization', token)
